.riponContent__partOne {
    //background-color: #ececec;
    padding: 20px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border: 1px solid #eee;
}

.riponContent__partTwo {
    //background-color: #ececec;
    padding: 20px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    border: 1px solid #eee;
}

.riponContent__partThree {
    margin-bottom: 20px;
    background-color: #ececec;
    padding: 20px;
    font-size: 14px;
}

.badge-secondary {
    color: #fff;
    background-color: #eb5D0b;
}