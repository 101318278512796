.reviewsSlider {
    margin-top: 20px !important;

    .slick-list {
        height: 200px;
    }

    h4 {
        font-size: 13px;
        font-weight: 700;
    }

    p {
        font-size: 12px;
    }

    .slick-slide {
        //border: 1px solid #eee;
        padding: 20px;
        background-color: #c1e8ff;

        img {
            border: 0px;
        }
    }
}

.slick-slide {
    margin: 3px;
    height: 200px;
}

.fa-star {
    color: #F7B202;
}

.verticalAlign {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 150px;
}