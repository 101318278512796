@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/variables";

$ktsblue: #002da0;
$ktsorange: #eb5D0b;

.topNavContacts {
    padding: 8px;
    color: $white;
    font-weight: 500;
    background-color: rgb(26, 26, 26);
}

.secondaryNavContacts {
    padding: 3px;
    //color: #eee;
    color: #242424!important;
    font-weight: 500;
    background-color: $ktsorange;
}

.bg-dark {
    background-color: #242424 !important;
}

.navbar-dark .navbar-brand {
    padding: 18px 0px;
}

.dropdown-menu{
    margin: 0px;
    border-radius: 0px;
    border: 0px;
}

.dropdown-item:hover {
    background-color: $ktsorange;
}

.dropdown-item:hover {
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

@media (max-width: 991px) {
    .nav-link:hover {
        padding: 6px !important;
    }
}

@media (min-width: 1201px) {
        .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link {
        color: #fff !important;
        -moz-transition: all .2s ease-in;
        -o-transition: all .2s ease-in;
        -webkit-transition: all .2s ease-in;
        transition: all .2s ease-in;
        font-weight: 500;
        font-size: 18px;
    }
}

@media (max-width: 1200px) {
    .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link {
    color: #fff !important;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    font-weight: 500;
    font-size: 15px;
    border-radius: 4px;
}
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    background-color: $ktsorange !important;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    font-weight: 500;
    border-radius: 4px;
}

.bannerContent {
    font-size: 15px;
    padding: 0px;
    display: flex;
    align-items: center;

    .innerBanner {
        color: $white
    }

    img {
        -webkit-filter: grayscale(1) invert(1);
        filter: grayscale(1) invert(1);
    }
}

.ktsLogo {
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1);
}

.innerBanner {
    margin-left: 10px;
    max-width: 100%;
    font-weight: 700;
    font-size: 13px;
}

.davantiLogo {
    width: 100%;
    text-align: right;
}

@media (min-width: 992px) {
    .riponButton {
        padding: 5px;
        color: $white;
        border-radius: 4px;
        background-color: $ktsorange;
    }   
}

@media (max-width: 991px) {
    .riponButton {
        text-align: center;
        padding: 10px;
        color: $white;
        border-radius: 4px;
        background-color: $ktsorange;
    }  
    
    .ktsLogo {
        margin-left: 10px;
    }

    .navbar-toggler {
        margin-right: 10px;
    }

    .mr-auto.navbar-nav {
        text-align: center;
    }
}

.infoService {
    width: 100%;
    text-align: center;
    padding: 5px;

    p {
        padding: 0px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
    }
} 