footer {
    text-align: center;
    background-color: #eb5D0b !important;
    padding: 8px;
    font-size: 12px;
    //position: fixed;
    //bottom: 0px;
    width: 100%;

    p {
        margin-bottom: 0px;
        color: #fff;
        font-weight: 500;

        a {
            color: #000;
        }
    }

    @media (max-width: 768px) {
        p {
            font-size: 12px;
        }
    }
}