.slick-slide img {
    border: 1px solid #eee;
}

.slick-slide {
    margin: 3px;
}

.slick-list {
    height: 80px;
}

.reviewsSlider .slick-slide {
    border-radius: 5px;
}

.reviewsSlider .slick-list {
    height: 210px;
}

.slick-slide img {
    border: 1px solid #eee;
    border-radius: 4px;
}