.errorPage {
    padding: 40px;
    font-weight: 700;
    text-align: center;
    background-color: #1a1a1a;
    color: #fff;
}

.errorContentContainer {
    padding: 0px;

}