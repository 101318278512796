.lightboxContainer {
    width: 70vw;
    height: 70vh;
    z-index: 999;
    position: fixed;
    background-color: #fff;
    top: calc(50vh - 35vh);
    left: calc(50vw - 35vw);
}

.lightboxBackground {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 555;
    width: 100vw;
    height: 100vh;
    background-color: rgba(24, 24, 24, 0.873)
}