.tyresContent__partOne {
    //background-color: #ececec;
    padding: 20px;
    margin-bottom: 16px;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    border: 1px solid #eee;
}

.tyresContent__partTwo {
    //background-color: #ececec;
    padding: 20px;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    border: 1px solid #eee;
}

.tyresContent__partThree {
    margin-bottom: 20px;
    background-color: #ececec;
    padding: 20px;
    font-size: 14px;
}

.contentTitle {
    background-color: #1a1a1a;
    color: #fff;
    padding: 20px;
    margin-bottom: 0px;
}

.sideImages {
    width: 100%;
    text-align: center;
    
    img {
        margin: 0 auto;
    }
}

.enviromental {
    background-color: #84ffa3b0;
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 15px;
}

.fa-leaf {
    color: #255732 !important;
}
