.alternateWelcomeBG {
    background-color: #242424 !important;
    padding: 40px;

    .contentContainer {
        background-color: transparent;
        color: #fff;

        @media (min-width: 768px) {
            #alternateWelcomeH1 {
                font-weight: 700;
                font-size: 28px;
            }
        }

        @media (max-width: 768px) {
            #alternateWelcomeH1 {
                font-weight: 700;
                font-size: 22px !important;
            }

            .stockCheckInput {
                width: 250px;
                margin: 20px auto;
            }

            .recruitmentBanner {
                margin: 10px auto;
            }
        }

        p {
            max-width: 450px;
        }

        .stockCheckInput {
            margin-top: 20px;
            width: 250px;

            button {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                background-color: #eb5D0b;
                color: #fff;
                border: 1px solid #eb5D0b;
            }
        }

        .recruitmentBanner {
            margin-top: 30px;
            padding: 10px;
            max-width: 400px;
            border-left: 2px solid #eb5D0b;
            font-size: 13px;

            a {
                display: block;
                margin-top: 10px;

                & {
                    color: #eb5D0b;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .contentContainer {
            text-align: center;
        }
    }
}

