.contentContainer {
    background-color: #fff;
    padding: 20px;
}

p {
    margin-bottom: 0px !important;
}

.introImages {
    padding: 0px !important;
}

@media (min-width: 1200px) {
    .carousel-caption {
        background-color: #242424 !important;
        color: #fff;
        padding: 18px;
        width: 400px;
        height: 160px;
        position: absolute;
        //right: 15%;
        top: 80px;
        left: 5%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: left;
    }
}

@media (max-width: 1199px) AND (min-width: 992px) {
    .carousel-caption {
        background-color: #242424 !important;
        color: #fff;
        padding: 18px;
        width: 400px;
        height: 160px;
        position: absolute;
        //right: 15%;
        top: 50px;
        left: 30px;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: left;
    }
}

@media (max-width: 992px) AND (min-width: 768px) {
    .carousel-caption {
        background-color: #242424 !important;
        color: #fff;
        padding: 18px;
        height: 110px;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: center;
        top: 40px;
    }

    h3 {
        font-size: 18px;
    }

    p {
        font-size: 12px;
    }
}

@media (max-width: 767px) {
    .carousel-caption {
        background-color: #242424 !important;
        color: #fff;
        padding: 15px;
        height: 110px;
        z-index: 10;
        padding-top: 15px;
        padding-bottom: 20px;
        color: #fff;
        text-align: center;
        top: 10px;
    }

    .carousel-indicators {
        bottom: -18px;
    }

    h3 {
        display:none;
        font-size: 15px;
    }

    p {
        font-size: 14px;
    }
}

.w-100 {
    width: 100%!important;
    min-height: 140px;
}

.brandsImage {
    padding: 40px;
    width: 100%;
}

.carousel-control-prev {
    display: none;
}

.carousel-control-next {
    display: none;
}

.pageTitle {
    color: #242424;

    &:after {
        display: block;
        height: 2px;
        background-color: #eb5D0b;
        content: " ";
        width: 200px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media (max-width: 900px) {
    .welcomeText {
        width: 100%;
        padding: 20px;
        color: #fff;
        background-color: #242424 !important;
        margin: 0px;
        text-align: center;
        font-weight: 300;
        font-size: 17px;
    }
}

@media (min-width: 901px) {
    .welcomeText {
        width: 100%;
        padding: 20px;
        color: #fff;
        background-color: #242424 !important;
        margin: 0px;
        text-align: center;
        font-weight: 300;
    }
}

.noMarginContainer {
    padding: 0px !important;
}

@media (max-width: 900px) {
    .introText {
        margin-bottom: 20px;
        background-color: rgb(236, 236, 236);
        text-align: center;
        padding: 20px;
        font-weight: 400;
        font-size: 15px;
    }
}

@media (min-width: 901px) {
    .introText {
        margin-bottom: 20px;
        background-color: rgb(236, 236, 236);
        text-align: center;
        padding: 20px;
        font-weight: 400;
        font-size: 1.05em;
    }
}

.locationData {
    text-align: center;
    //border: 1px solid #eee;
    padding: 20px;
    color: #242424;
    background-color: #ececec;
    height: 250px;
}

.locationData__address {
    padding: 10px;
    //border: 1px solid #eee;
    font-size: 14px;
    background-color: #2d2d2d;
    color: #eee;
}

.locationData__hours {
    padding: 10px;
    //border: 1px solid #eee;
    font-size: 14px;
    background-color: #2d2d2d;
    color: #eee;
}

.findUs {
    text-align: center;
    height: 250px;
    margin-bottom: 20px;
    
    .directionsText {
        font-size: 14px;
        margin-bottom: 20px !important;
        font-weight: 700;
    }

    .directionsSubText {
        margin-top: 20px;
        font-size: 12px;
        margin-bottom: 20px !important;
        font-weight: 500;
    }
}

.btn-secondary {
    background-color: #eb5D0b;
    border: 0px;
}

.input-group-text {
    background-color: #242424;
    color: #eee;
}

.findUs {
    text-align: center;
    padding: 20px;
    background-color: #ececec;
}

.verticalFindUsAlign {
    margin: 0 auto;
    max-width: 450px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 200px;
}

.mb-3 {
    width: 100%
}

.services_Left {
    height: 300px;
    text-align: right;

    .content {
        height: 300px;
        display:table-cell;
        vertical-align:middle; 
        width: 1%;

        h3 {
            font-size: 1.5rem;
            font-weight: 700 !important;
        }
        p {
            font-size: 16px;
            font-weight: 300;
            line-height: 1.5;
            margin-bottom: 2rem;
        }
    
        hr {
            color: inherit;
            border-color: #eb5D0b;
            border-style: solid none none;
            border-width: 2px medium medium;
            border-image: none 100% / 1 / 0 stretch;
            width: 3em;
            margin-right: 0px;
        }
    }
}

@media (max-width: 768px) {
    .services_Left {
        margin-top: 20px;
        height: auto;
        text-align: left;

        .content {
            height: 200px !important;
        }

        h3 {
            display: block;
            font-size: 18px !important;
        }
    }
}

.services_Right {
    height: 300px;
    text-align: left;

    .content {
        height: 300px;
        display:table-cell;
        vertical-align:middle;  

        h3 {
            font-size: 1.5rem;
            font-weight: 700 !important;
        }
    
        p {
            font-size: 14px;
            font-weight: 300;
            line-height: 1.5;
            margin-bottom: 2rem;
        }
    
        hr {
            color: inherit;
            border-color: #eb5D0b;
            border-style: solid none none;
            border-width: 2px medium medium;
            border-image: none 100% / 1 / 0 stretch;
            width: 3em;
            margin-left: 0px;
        }

    }
}

@media (max-width: 768px) {
    .services_Right {
        margin-top: 20px;
        height: auto;
        text-align: left;

        .content {
            height: 180px !important;
        }

        h3 {
            display: block;
            font-size: 18px !important;
        }
    }
}

.learnMore {
    margin-top: 10px;
    font-weight: 700;
    width: auto;
    border-radius: 0.25rem;
    color: rgb(40, 40, 40);
    background-color: transparent;
    border: 0.0625rem solid;
    height: 40px;
    font-size: 14px;
}