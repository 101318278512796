.card {
    border-radius: 0px;
    border: 0px;
    text-align: center;

    .card-header {
        color: #eee;
        font-weight: 500;
        background-color: #eb5D0b;
    }

    .card-body {
        color: #fff;
        background-color: #242424 !important;
    }
}

@media (max-width: 992px) {
    .card{
        margin-bottom: 20px;
    }
}