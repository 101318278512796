.App {
    background-color: white;
}

.Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.Auth-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: white;
}

.Auth-form-content {
    padding: 12%;
}

.Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 800;
}

label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
}

.signInLogo {
    width: 250px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}

.basicError {
    text-align: center;
    padding: 10px;
    background-color: #f44336;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
}

.basicSuccess {
    text-align: center;
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
}

.mobile {
    margin: 0 auto;
    margin-top: 20px;
    width: 500px;
}

.table {
    text-align: center;
    vertical-align: middle;

    td {
        vertical-align: middle;
    }

    button {
        background-color: #0d6efd !important;
    }
}