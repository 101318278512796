.batteriesContent__partOne {
    //background-color: #ececec;
    padding: 20px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border: 1px solid #eee;
}

.batteriesContent__partTwo {
    //background-color: #ececec;
    padding: 20px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border: 1px solid #eee;
}

.batteriesContent__partThree {
    margin-bottom: 20px;
    background-color: #ececec;
    padding: 20px;
    font-size: 14px;
}

